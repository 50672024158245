/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import NextErrorComponent, { ErrorProps } from 'next/error';
import * as Sentry from '@sentry/nextjs';
import { notifySentry } from '@root/utils/sentry';
import { Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import Link from '@root/components/Link';
import { Page, PageComponent } from './Type';
import styles from './Error.module.scss';
import ni18nConfig from '@root/../ni18n.config';
import { loadTranslations, Ni18nServerState } from 'ni18n';
import { localeFromContext } from '@root/plugins/i18n';

export type ErrorPageProps = ErrorProps &
    Ni18nServerState & {
        hasGetInitialPropsRun?: boolean;
        err?:
            | (Error & {
                  statusCode?: number | undefined;
              })
            | null
            | undefined;
    };

type StatusCodeProps = {
    code: number;
};

const StatusCode: PageComponent<StatusCodeProps> = ({ code }) => {
    return <h3 className={styles.status_code}>{code === 404 ? 404 : 500}</h3>;
};

const ErrorPage: Page<ErrorPageProps> = ({
    statusCode,
    hasGetInitialPropsRun,
    err,
}) => {
    const keySuffix = statusCode === 404 ? 'PageNotFound' : 'FatalError';
    if (!hasGetInitialPropsRun && err) {
        // getInitialProps is not called in case of
        // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
        // err via _app.js so it can be captured
        Sentry.captureException(err);
        // Flushing is not required in this case as it only happens on the client
    }
    return (
        <>
            <Helmet>
                <body className={styles.body} />
            </Helmet>
            <div className={styles.container}>
                <div
                    className={classnames('container', styles.inner_container)}
                >
                    <div className={styles.logo_container}>
                        <Link href="landing_home">
                            <a>
                                <img
                                    src="/static/img/logos/logo.svg"
                                    alt="logo"
                                />
                            </a>
                        </Link>
                    </div>
                    <div className={styles.text_container}>
                        <h1 className={styles.title}>
                            <Trans i18nKey={`title${keySuffix}`} ns="errorPage">
                                Oops, this page is not found
                            </Trans>
                        </h1>
                        <h2 className={styles.description}>
                            <Trans
                                i18nKey={`description${keySuffix}`}
                                ns="errorPage"
                            >
                                We're sorry, but this page doesn't exists
                            </Trans>
                        </h2>
                        {statusCode === 404 && (
                            <Link href="landing_home">
                                <a className={styles.link}>
                                    <Trans
                                        i18nKey="linkPageNotFound"
                                        ns="errorPage"
                                    >
                                        Go back to homepage
                                    </Trans>
                                </a>
                            </Link>
                        )}
                    </div>
                    <StatusCode code={statusCode} />
                </div>
            </div>
        </>
    );
};

ErrorPage.getInitialProps = async (ctx) => {
    const { req, res, err, asPath, ...context } = ctx;
    const errorInitialProps: ErrorProps = {
        ...(await NextErrorComponent.getInitialProps({
            req,
            res,
            err,
            ...context,
        } as any)),
    };

    // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
    // getInitialProps has run
    (errorInitialProps as any).hasGetInitialPropsRun = true;

    const locale = localeFromContext(ctx);
    const cfgNs = typeof ni18nConfig.ns !== 'undefined' ? ni18nConfig.ns : [];
    const translates = await loadTranslations(
        ni18nConfig,
        locale,
        !Array.isArray(cfgNs) ? [cfgNs] : cfgNs,
    );

    // Running on the server, the response object (`res`) is available.
    //
    // Next.js will pass an err on the server if a page's data fetching methods
    // threw or returned a Promise that rejected
    //
    // Running on the client (browser), Next.js will provide an err if:
    //
    //  - a page's `getInitialProps` threw or returned a Promise that rejected
    //  - an exception was thrown somewhere in the React lifecycle (render,
    //    componentDidMount, etc) that was caught by Next.js's React Error
    //    Boundary. Read more about what types of exceptions are caught by Error
    //    Boundaries: https://reactjs.org/docs/error-boundaries.html

    if (errorInitialProps.statusCode === 404) {
        // Opinionated: do not record an exception in Sentry for 404
        return { ...translates, statusCode: 404 };
    }

    if (err) {
        notifySentry(err, req, errorInitialProps.statusCode);

        // Flushing before returning is necessary if deploying to Vercel, see
        // https://vercel.com/docs/platform/limits#streaming-responses
        await Sentry.flush(2000);

        return { ...translates, ...errorInitialProps };
    }

    // If this point is reached, getInitialProps was called without any
    // information about what the error might be. This is unexpected and may
    // indicate a bug introduced in Next.js, so record it in Sentry
    Sentry.captureException(
        new Error(`_error.tsx getInitialProps missing data at path: ${asPath}`),
    );
    await Sentry.flush(2000);

    return { ...translates, ...errorInitialProps };
};

export default ErrorPage;
